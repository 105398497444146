import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Order = () => {
  return (
    <Layout>
      <SEO title="Ordering" />

      <div className="flex justify-center">
        <div className="container flex justify-center items-center h-96">
          <div className="flex flex-col justify-center items-center gap-6">
            <a
              href="https://www.grubhub.com/restaurant/leftys-wings--grill-1034-se-port-st-lucie-blvd-port-saint-lucie/823432"
              className="bg-blue-800 block py-2 px-6 text-2xl text-white rounded-lg shadow-lg font-bold"
            >
              Order from GrubHub
            </a>
            <div className="text-2xl text-gray-500 italic font-bold tracking-wide">
              {" "}
              -OR-{" "}
            </div>
            <a
              href="https://www.doordash.com/store/lefty-s-wings---raw-bar--port-st--port-st-lucie-372856/en-us"
              className="bg-blue-800 block py-2 px-6 text-2xl text-white rounded-lg shadow-lg font-bold"
            >
              Order from DoorDash
            </a>{" "}
            {/* <div className="text-2xl text-gray-500 italic font-bold tracking-wide">
              {" "}
              -OR-{" "}
            </div>
            <a
              href="tel:+17723373212"
              className="bg-blue-800 block py-2 px-6 text-2xl text-white rounded-lg shadow-lg font-bold"
            >
              Call Us
            </a> */}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Order
